import React, { useEffect } from 'react';
import {
  makeStyles,
  Divider,
  Grid,
  List,
  ListItem,
  Typography,
  ListItemText,
  ListItemAvatar,
  Avatar,
  useTheme,
  Chip,
  Hidden,
} from '@material-ui/core';
import { SectionHeader } from 'components/molecules';
import { Image } from 'components/atoms';
import { Section, SectionAlternate } from 'components/organisms';
import { EntityFeatures, Hero, EntitiesShowcaseSimilar } from './components';
import { SolutionsGrid } from './components';
import { EntitiesGrid } from './components';
import { gql } from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { WindMillLoading } from 'react-loadingg';
import { Link } from 'gatsby';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import LanguageIcon from '@material-ui/icons/Language';
import { deduplicateArray } from 'helpers/helpers-array.js';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
  loadingContainer: {
    marginTop: '30px',
    height: '400px',
    display: 'inline-flex',
    justifyContent: 'center',
  },
  inline: {
    display: 'inline',
  },
  sdgContainer: {
    height: '60px',
    width: '60px',
    margin: '10px',
  },
  sdgImage: {
    objectFit: 'contain',
  },
  fontWeight900: {
    fontWeight: 900,
  },
  dividers: {
    marginTop: '20px',
    marginBottom: '20px',
  },
  chipArea: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  iconRoot: {
    verticalAlign: 'middle',
  },
}));

const DetailedEntityPage = ({ entity_id }): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const empty_filter = {
    cursor: null,
    industries: [],
    geographies: [],
    number_employees: [],
    status: [],
  };

  const [filterData, setFilterData] = React.useState<Filter>(empty_filter);
  const [shouldUpdate, setShouldUpdate] = React.useState<boolean>(true);

  const GETENTITYDETAILS = gql`
    query faunaGetEntityDetails($query_id: ID!) {
      findEntitiesByID(id: $query_id) {
        _id
        name
        banner_img
        logo_img
        description
        tagline
        status
        number_employees
        facebook_url
        instagram_url
        website_url
        sustainability_url
        scope_of_operations
        industries {
          data {
            _id
            name
          }
        }
        geographies {
          data {
            _id
            name
          }
        }
        supported_solutions {
          data {
            partner_entity {
              _id
              name
              banner_img
              logo_img
              description
              tagline
              status
              number_employees
              industries {
                data {
                  _id
                  name
                }
              }
              geographies {
                data {
                  _id
                  name
                }
              }
            }
            solution {
              _id
              name
              banner_img
              description
              value_chain_impact
              entity {
                data {
                  name
                  geographies {
                    data {
                      _id
                      name
                    }
                  }
                }
              }
              sdgs {
                data {
                  _id
                  name
                  sdg_id
                  logo_square_img
                }
              }
              stakeholders {
                data {
                  _id
                  name
                }
              }
              themes {
                data {
                  _id
                  name
                }
              }
            }
          }
        }
        solutions(_size: 6) {
          data {
            _id
            name
            banner_img
            description
            value_chain_impact
            entity {
              data {
                name
                geographies {
                  data {
                    _id
                    name
                  }
                }
              }
            }
            sdgs {
              data {
                _id
                name
                sdg_id
                logo_square_img
              }
            }
            target_industry {
              data {
                _id
                name
              }
            }
            stakeholders {
              data {
                _id
                name
              }
            }
            themes {
              data {
                _id
                name
              }
            }
            solution_types {
              data {
                _id
                name
              }
            }
          }
        }
      }
    }
  `;

  const { loading, error, data } = useQuery(GETENTITYDETAILS, {
    variables: {
      query_id: entity_id,
    },
  });
  console.log(entity_id);
  useEffect(() => {
    if (data && shouldUpdate) {
      setFilterData({
        ...empty_filter,
        industries: data.findEntitiesByID.industries.data.map(
          (item, index) => item._id,
        ),
        geographies: data.findEntitiesByID.geographies.data.map(
          (item, index) => item._id,
        ),
        number_employees: [data.findEntitiesByID.number_employees],
        status: [data.findEntitiesByID.status],
      });
      setShouldUpdate(false);
    }
    [data];
  }, [data]);

  const filtersEntitiesSameIndustry: EntityFilter = {
    cursor: null,
    industries: filterData.industries,
    geographies: [],
    number_employees: [],
    status: [],
  };

  return (
    <div className={classes.root}>
      {loading ? (
        <Section>
          <Grid container spacing={0} justify="center">
            <Grid xs={3} sm={4} />
            <Grid container item xs={6} sm={4} justify="center">
              <div className={classes.loadingContainer}>
                <WindMillLoading
                  color="green"
                  size="large"
                  style={{ position: 'relative', display: 'inline-block' }}
                />
              </div>
            </Grid>
            <Grid xs={3} sm={4} />
          </Grid>
        </Section>
      ) : (
        <>
          <Hero
            cover={{
              src: data.findEntitiesByID.banner_img,
              srcSet: data.findEntitiesByID.banner_img,
            }}
            title={data.findEntitiesByID.name}
            subtitle={
              <div>
                <Typography variant="body2" color="textSecondary">
                  {data.findEntitiesByID.geographies.data[0] && (
                    <>
                      <LanguageIcon
                        fontSize="small"
                        classes={{ root: classes.iconRoot }}
                      />{' '}
                      {data.findEntitiesByID.geographies.data[0].name}
                    </>
                  )}
                  <>
                    <Hidden smUp>
                      <br />
                    </Hidden>
                    {'  '}
                  </>
                  {data.findEntitiesByID.number_employees && (
                    <>
                      <PeopleAltIcon
                        fontSize="small"
                        classes={{ root: classes.iconRoot }}
                      />{' '}
                      {data.findEntitiesByID.number_employees} employees
                      {'  '}
                    </>
                  )}
                </Typography>
              </div>
            }
            logo={{
              photo: {
                src: data.findEntitiesByID.logo_img
                  ? data.findEntitiesByID.logo_img
                  : null,
                srcSet: data.findEntitiesByID.logo_img
                  ? data.findEntitiesByID.logo_img
                  : null,
              },
              name: data.findEntitiesByID.name,
            }}
            social={{
              facebook: data.findEntitiesByID.facebook_url
                ? data.findEntitiesByID.facebook_url
                : null,
              website: data.findEntitiesByID.website_url
                ? data.findEntitiesByID.website_url
                : null,
              instagram: data.findEntitiesByID.instagram_url
                ? data.findEntitiesByID.instagram_url
                : null,
              sustainability: data.findEntitiesByID.sustainability_url
                ? data.findEntitiesByID.sustainability_url
                : null,
            }}
          />
          <Section>
            <Grid container spacing={1}>
              <Grid container item xs={12}>
                <Grid item xs={12} sm={6}>
                  <SectionHeader
                    title={`About ${data.findEntitiesByID.name}`}
                    align="left"
                    titleVariant="h5"
                    titleProps={{ className: classes.fontWeight900 }}
                  />
                  <Typography
                    color="textPrimary"
                    variant="body1"
                    component="span"
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: data.findEntitiesByID.description,
                      }}
                    />
                  </Typography>
                </Grid>
                <Hidden smUp>
                  {' '}
                  <Grid item xs={12}>
                    <Divider classes={{ root: classes.dividers }} />
                  </Grid>
                </Hidden>
                <Grid container item xs={12} sm={6}>
                  <SectionHeader
                    title={`Impact Classification`}
                    align="left"
                    titleVariant="h5"
                    titleProps={{ className: classes.fontWeight900 }}
                  />
                  <Divider classes={{ root: classes.dividers }} />
                  <Grid item xs={12} sm={6}>
                    <EntityFeatures
                      image_url={`/icon/scope_of_operations.png`}
                      name={`Scope of Operations`}
                      attributes={[
                        {
                          name: data.findEntitiesByID.scope_of_operations,
                        },
                      ]}
                      color="pastelRed"
                      filterKey="scope_of_operations"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <EntityFeatures
                      image_url={`/icon/stakeholders.png`}
                      name={`Stakeholders`}
                      attributes={
                        data.findEntitiesByID.solutions.data.length !== 0
                          ? deduplicateArray(
                              data.findEntitiesByID.solutions.data.reduce(
                                (accumulator, currentValue) => {
                                  if (currentValue.stakeholders) {
                                    return [
                                      ...accumulator,
                                      ...currentValue.stakeholders.data,
                                    ];
                                  } else {
                                    return accumulator;
                                  }
                                },
                                [],
                              ),
                              'name',
                            )
                          : deduplicateArray(
                              data.findEntitiesByID.supported_solutions.data.reduce(
                                (accumulator, currentValue) => {
                                  if (currentValue.solution) {
                                    return [
                                      ...accumulator,
                                      ...currentValue.solution.stakeholders
                                        .data,
                                    ];
                                  } else {
                                    return accumulator;
                                  }
                                },
                                [],
                              ),
                              'name',
                            )
                      }
                      color="pastelGreen"
                      filterKey="stakeholders"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <EntityFeatures
                      image_url={`/icon/industries.png`}
                      name={`Industries`}
                      attributes={data.findEntitiesByID.industries.data}
                      color="pastelBlue"
                      filterKey="industries"
                      path="/all-entities"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <EntityFeatures
                      image_url={`/icon/value_chain_impact.png`}
                      name={`Area of business`}
                      attributes={
                        data.findEntitiesByID.solutions.data.length !== 0
                          ? deduplicateArray(
                              data.findEntitiesByID.solutions.data.reduce(
                                (accumulator, currentValue) => {
                                  const x = accumulator.find(
                                    item => item.name === currentValue.name,
                                  );
                                  if (currentValue.value_chain_impact && !x) {
                                    return [
                                      ...accumulator,
                                      ...[
                                        {
                                          name: currentValue.value_chain_impact,
                                        },
                                      ],
                                    ];
                                  } else {
                                    return accumulator;
                                  }
                                },
                                [],
                              ),
                              'name',
                            )
                          : deduplicateArray(
                              data.findEntitiesByID.supported_solutions.data.reduce(
                                (accumulator, currentValue) => {
                                  const x = accumulator.find(
                                    item => item.name === currentValue.name,
                                  );
                                  if (currentValue.solution && !x) {
                                    return [
                                      ...accumulator,
                                      ...[
                                        {
                                          name:
                                            currentValue.solution
                                              .value_chain_impact,
                                        },
                                      ],
                                    ];
                                  } else {
                                    return accumulator;
                                  }
                                },
                                [],
                              ),
                              'name',
                            )
                      }
                      color="pastelYellow"
                      filterKey="value_chain_impact"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Divider classes={{ root: classes.dividers }} />
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={12} sm={6}>
                  <SectionHeader
                    title={`Themes they support`}
                    align="left"
                    titleVariant="h5"
                    titleProps={{ className: classes.fontWeight900 }}
                  />
                  <div className={classes.chipArea}>
                    {data.findEntitiesByID.solutions.data.length !== 0
                      ? deduplicateArray(
                          data.findEntitiesByID.solutions.data.reduce(
                            (accumulator, currentValue) => {
                              const x = accumulator.find(
                                item =>
                                  item.name === currentValue.themes.data.name,
                              );
                              if (currentValue.themes && !x) {
                                return [
                                  ...accumulator,
                                  ...currentValue.themes.data,
                                ];
                              } else {
                                return accumulator;
                              }
                            },
                            [],
                          ),
                          '_id',
                        ).map((item, index) => (
                          <Link
                            to={'/all-solutions'}
                            state={{ themes: [`${item._id}`] }}
                          >
                            <Chip
                              key={index}
                              color="textPrimary"
                              label={item.name}
                            />
                          </Link>
                        ))
                      : deduplicateArray(
                          data.findEntitiesByID.supported_solutions.data.reduce(
                            (accumulator, currentValue) => {
                              const x = accumulator.find(
                                item =>
                                  item.name ===
                                  currentValue.solution.themes.data.name,
                              );
                              if (currentValue.solution && !x) {
                                return [
                                  ...accumulator,
                                  ...currentValue.solution.themes.data,
                                ];
                              } else {
                                return accumulator;
                              }
                            },
                            [],
                          ),
                          '_id',
                        ).map((item, index) => (
                          <Link
                            to={'/all-solutions'}
                            state={{ themes: [`${item._id}`] }}
                          >
                            <Chip
                              key={index}
                              color="textPrimary"
                              label={item.name}
                            />
                          </Link>
                        ))}
                  </div>
                </Grid>

                <Hidden smUp>
                  {' '}
                  <Grid item xs={12}>
                    <Divider classes={{ root: classes.dividers }} />
                  </Grid>
                </Hidden>

                <Grid item container xs={12} sm={6}>
                  <SectionHeader
                    title={`Social development goals`}
                    align="left"
                    titleVariant="h5"
                    titleProps={{ className: classes.fontWeight900 }}
                  />
                  {data.findEntitiesByID.solutions.data.length !== 0
                    ? deduplicateArray(
                        data.findEntitiesByID.solutions.data.reduce(
                          (accumulator, currentValue) => {
                            const x = accumulator.find(
                              item => item.name === currentValue.sdgs.data.name,
                            );
                            if (currentValue.sdgs && !x) {
                              return [
                                ...accumulator,
                                ...currentValue.sdgs.data,
                              ];
                            } else {
                              return accumulator;
                            }
                          },
                          [],
                        ),
                        '_id',
                      ).map((item, index) => (
                        <Link
                          to={'/all-solutions'}
                          state={{ sdgs: [`${item._id}`] }}
                        >
                          <div key={index} className={classes.sdgContainer}>
                            <Image
                              src={item.logo_square_img}
                              srcSet={item.logo_square_img}
                              alt="A kitten"
                              className={classes.sdgImage}
                            />
                          </div>
                        </Link>
                      ))
                    : deduplicateArray(
                        data.findEntitiesByID.supported_solutions.data.reduce(
                          (accumulator, currentValue) => {
                            console.log(currentValue);
                            const x = accumulator.find(
                              item =>
                                item.name ===
                                currentValue.solution.sdgs.data.name,
                            );
                            if (currentValue.solution && !x) {
                              return [
                                ...accumulator,
                                ...currentValue.solution.sdgs.data,
                              ];
                            } else {
                              return accumulator;
                            }
                          },
                          [],
                        ),
                        '_id',
                      ).map((item, index) => (
                        <Link
                          to={'/all-solutions'}
                          state={{ sdgs: [`${item._id}`] }}
                        >
                          <div key={index} className={classes.sdgContainer}>
                            <Image
                              src={item.logo_square_img}
                              srcSet={item.logo_square_img}
                              alt="A kitten"
                              className={classes.sdgImage}
                            />
                          </div>
                        </Link>
                      ))}
                </Grid>
              </Grid>
            </Grid>
          </Section>

          {data.findEntitiesByID.solutions.data.length !== 0 ? (
            <SectionAlternate>
              <Grid container>
                <Grid item xs={12}>
                  <SectionHeader
                    title={`${data.findEntitiesByID.name} offers the following solutions`}
                    align="left"
                    titleVariant="h4"
                    titleProps={{ className: classes.fontWeight900 }}
                  />
                </Grid>
              </Grid>
              <SolutionsGrid dataPlot={data.findEntitiesByID.solutions.data} />
            </SectionAlternate>
          ) : (
            ''
          )}

          {data.findEntitiesByID.supported_solutions.data.length !== 0 ? (
            <SectionAlternate>
              <Grid container>
                <Grid item xs={12}>
                  <SectionHeader
                    title={`${data.findEntitiesByID.name} is improving its impact by working with the following partners`}
                    align="left"
                    titleVariant="h4"
                    titleProps={{ className: classes.fontWeight900 }}
                  />
                </Grid>
              </Grid>
              <EntitiesGrid
                dataPlot={data.findEntitiesByID.supported_solutions.data.map(
                  (item, index) => item.partner_entity,
                )}
              />
            </SectionAlternate>
          ) : (
            ''
          )}

          <Section>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <EntitiesShowcaseSimilar
                  title="Entities in the same industries"
                  filter={filtersEntitiesSameIndustry}
                  number_options={3}
                />
              </Grid>
            </Grid>
          </Section>
        </>
      )}
    </div>
  );
};

export default DetailedEntityPage;
