// Deduplicate an array of object on the base of a given key in the dictionary
function deduplicateArray(array, key) {
	return array.reduce((accumulator, currentValue) => {
		const x = accumulator.find(item => item[key] === currentValue[key]);
		if (!x) {
			return [...accumulator, currentValue];
		} else {
			return accumulator;
		}
	}, []);
}

exports.deduplicateArray = deduplicateArray;
