import React from 'react';
import {
  makeStyles,
  Divider,
  Grid,
  List,
  ListItem,
  Typography,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Chip,
  useTheme,
} from '@material-ui/core';
import { Link } from 'gatsby';
import { toTitleCase } from 'helpers/helpers-string';
import { filterKeyInString, filterKeyForEntity } from 'global/global-variables';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  avatar: {
    height: 60,
    width: 60,
    marginRight: 20,
  },
  chipArea: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

const EntityFeatures = ({
  image_url,
  name,
  attributes,
  color,
  filterKey,
  path,
}): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <List className={classes.root}>
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt={name} src={image_url} className={classes.avatar} />
        </ListItemAvatar>
        <ListItemText
          primary={
            <>
              <b>{name}</b>
            </>
          }
          secondary={
            <>
              <div className={classes.chipArea}>
                {attributes.map((item, index) => (
                  <Link
                    to={path ? path : '/all-solutions'}
                    state={
                      filterKeyInString.includes(filterKey)
                        ? { [filterKey]: [`${item.name}`] }
                        : { [filterKey]: [`${item._id}`] }
                    }
                  >
                    <Chip
                      key={index}
                      color="primary"
                      label={
                        filterKeyInString.includes(filterKey)
                          ? toTitleCase(item.name)
                          : item.name
                      }
                      style={{
                        backgroundColor: `${theme.palette[color].main}`,
                        color: 'black',
                      }}
                    />
                  </Link>
                ))}
              </div>
            </>
          }
        />
      </ListItem>
    </List>
  );
};

export default EntityFeatures;
