import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Grid,
  Button,
  useMediaQuery,
  Typography,
  Container,
  IconButton,
  Hidden,
  Box,
} from '@material-ui/core';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { SectionHeader } from 'components/molecules';
import { Image } from 'components/atoms';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { gql } from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { WindMillLoading } from 'react-loadingg';
import EntitiesGrid from './EntitiesGrid';

const useStyles = makeStyles(theme => ({
  grid: {
    justifyContent: 'center',
  },
  fontWeight900: {
    fontWeight: 900,
  },
  loadingContainer: {
    marginTop: '30px',
    height: '400px',
    display: 'inline-flex',
    justifyContent: 'center',
  },
  categoryIconButton: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
  themeColors: {
    color: theme.palette.primary.light,
  },
  wordHighlight: {
    color: 'orange',
  },
  verticalAlign: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  loadMore: {
    marginTop: '20px',
    marginBottom: '20px',
    justifyContent: 'center',
  },
  loadingAnimation: {
    position: 'relative',
  },
}));

const EntitiesShowcaseSimilar = ({
  className,
  filter,
  title,
  number_options,
  ...rest
}: ViewComponentProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();

  const [dataPlot, setDataPlot] = useState([]);
  const [shouldUpdate, setShouldUpdate] = useState(true);

  const GETENTITIES = gql`
    query faunaFilterEntities(
      $industries: [String]!
      $geographies: [String]!
      $number_employees: [String]!
      $status: [String]!
      $cursor: String
      $number_options: Int
    ) {
      filterEntities(
        industries: $industries
        geographies: $geographies
        number_employees: $number_employees
        status: $status
        _cursor: $cursor
        _size: $number_options
      ) {
        after
        before
        data {
          _id
          name
          banner_img
          logo_img
          description
          tagline
          status
          number_employees
          industries {
            data {
              _id
              name
            }
          }
          geographies {
            data {
              _id
              name
            }
          }
          supported_solutions(_size: 80) {
            data {
              partner_entity {
                _id
                name
              }
            }
          }
        }
      }
    }
  `;

  const { loading, error, data } = useQuery(GETENTITIES, {
    variables: {
      industries: filter.industries,
      geographies: filter.geographies,
      number_employees: filter.number_employees,
      status: filter.status,
      cursor: null,
      number_options: number_options,
    },
  });

  return (
    <Grid container>
      {data && data.length > 0 ? (
        <>
          <Grid item xs={8}>
            <SectionHeader
              title={title}
              align="left"
              titleVariant="h4"
              titleProps={{ className: classes.fontWeight900 }}
            />
          </Grid>
          <Grid item xs={4}>
            <Link
              to={'/all-entities'}
              className={clsx(
                'card-category-link__item',
                classes.categoryIconButton,
              )}
              state={filter}
            >
              <Typography className={classes.verticalAlign}>See all</Typography>
              <IconButton className="card-category-link__icon-button">
                <ArrowRightAltIcon
                  className={clsx(
                    'card-category-link__icon',
                    classes.themeColors,
                  )}
                />
              </IconButton>
            </Link>
          </Grid>
          <EntitiesGrid dataPlot={data.filterEntities.data} />
        </>
      ) : (
        ''
      )}

      <Grid container spacing={0} justify="center">
        <Grid container item xs={12} justify="center">
          {loading ? (
            <>
              <div className={classes.loadingContainer}>
                <WindMillLoading
                  color="green"
                  size="large"
                  style={{ position: 'relative', display: 'inline-block' }}
                />
              </div>
            </>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EntitiesShowcaseSimilar;
